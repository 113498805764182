<script>
import {
  BadgeDesign,
  CardDesign,
  CertificateDesign,
} from '../../consts/designPresets'
import { TEMPLATE_EDITOR_CREATE, UPLOAD_FILE } from '../../store/actions'
import SuccessModal from '@/modals/SuccessModal.vue'

const API_URL_ASSETS = process.env.VUE_APP_API_URL_ASSETS

export default {
  components: {
    SuccessModal,
  },
  data() {
    return {
      designType: 'Badge',
      designTypeError: false,
      templateName: null,
      templateNameError: false,
      photoFromFile: null,
      photoFile: null,
      photoFileError: false,
      hasErrors: false,
      errorMsg: null,
      loading: false,
      message: 'design',
      showConfirmCreateModal: false,
      tid: null,
      timeout: null,
      API_URL_ASSETS,
    }
  },
  computed: {
    isDisabled() {
      return (
        this.designTypeError || this.templateNameError || this.photoFileError
      )
    },
  },
  mounted() {
    const el = document.getElementById('fileUpload')

    if (el) {
      el.addEventListener('change', (event) => {
        if (event.target.files.length !== 0) {
          this.photoFile = event.target.files[0]
          this.photoFromFile = URL.createObjectURL(this.photoFile)
          this.photoFileError = false
        }
      })
    }
  },
  methods: {
    handleClose() {
      console.log('handleClose', this.tid)
      if (this.tid) {
        this.$router.push({
          name: `ViewTemplate`,
          params: { tid: this.tid },
        })
      }
      clearTimeout(this.timeout)
    },
    validateDesign() {
      this.hasErrors = false

      if (!this.designType) {
        this.designTypeError = true
        this.hasErrors = true
      }

      if (!this.templateName) {
        this.templateNameError = true
        this.hasErrors = true
      }

      if (!this.photoFile) {
        this.photoFileError = true
        this.hasErrors = true
      }

      return !this.hasErrors
    },
    async addDesign() {
      this.loading = true

      try {
        if (this.validateDesign()) {
          this.debug('addDesign', this.designType)
          let preview
          switch (this.designType) {
            case 'Badge':
              preview = `${API_URL_ASSETS}/backgrounds/badge-design.png`

              BadgeDesign.components[1].src = await this.$store
                .dispatch(UPLOAD_FILE, {
                  file: this.photoFile,
                  folder: 'logos',
                })
                .catch((err) => {
                  console.error(err)
                })
              this.$store.dispatch('setTemplate', BadgeDesign)
              break
            case 'Certificate':
              preview = `${API_URL_ASSETS}/backgrounds/certificate-design.png`

              CertificateDesign.components[1].src = await this.$store
                .dispatch(UPLOAD_FILE, {
                  file: this.photoFile,
                  folder: 'logos',
                })
                .catch((err) => {
                  console.error(err)
                })
              this.$store.dispatch('setTemplate', CertificateDesign)
              break
            case 'Card':
              preview = `${API_URL_ASSETS}/backgrounds/card-design.png`

              CardDesign.components[7].src = await this.$store
                .dispatch(UPLOAD_FILE, {
                  file: this.photoFile,
                  folder: 'logos',
                })
                .catch((err) => {
                  console.error(err)
                })

              this.$store.dispatch('setTemplate', CardDesign)
              this.$store.commit('updateLayoutBackgroundColor', 'transparent')

              break
            default:
              break
          }

          this.$store.commit('setTemplateName', this.templateName)

          await this.$store
            .dispatch(TEMPLATE_EDITOR_CREATE, { preview })
            .then((data) => {
              console.log(data)
              this.tid = data.tid
              this.showConfirmCreateModal = true

              this.timeout = setTimeout(() => {
                this.handleClose()
              }, 1000 * 3)
            })
            .catch((err) => {
              throw err
            })
        }
      }
      catch (error) {
        console.error(error)
      }
      finally {
        this.loading = false
      }
    },
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
  },
}
</script>

<template>
  <v-container class="preset px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header">
          {{ $t('credentialDesign.preset.ChoosePresetTemplate') }}
        </h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          :disabled="isDisabled"
          :loading="loading"
          class="next"
          @click="addDesign"
        >
          {{ $t('credentialDesign.addDesignButton') }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="">
        <v-card elevation="0">
          <v-container class="text-left">
            <v-row>
              <v-col cols="12" class="">
                <h3 class="subtitle_text_strong">
                  {{ $t('credentialDesign.preset.subtitle') }}
                </h3>
              </v-col>
              <v-col cols="12" md="10" class="input-field pr-md-10">
                <v-radio-group
                  v-model="designType"
                  row
                  hide-details
                  :class="{ 'error-radio': designTypeError }"
                  class="mt-0"
                  @change="designTypeError = false"
                >
                  <v-radio
                    color="#009fb1"
                    :label="$t('credentialDesign.preset.types[0]')"
                    :value="$t('credentialDesign.preset.types[0]')"
                  />
                  <v-radio
                    color="#009fb1"
                    :label="$t('credentialDesign.preset.types[1]')"
                    :value="$t('credentialDesign.preset.types[1]')"
                  />
                  <v-radio
                    color="#009fb1"
                    :label="$t('credentialDesign.preset.types[2]')"
                    :value="$t('credentialDesign.preset.types[2]')"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="10"
                class="d-flex justify-space-between align-start flex-wrap pr-md-10"
              >
                <v-img
                  max-width="186px"
                  contain
                  :src="`${API_URL_ASSETS}/backgrounds/badge-design.png`"
                  @click="designType = $t('credentialDesign.preset.types[0]')"
                />
                <v-img
                  max-width="270px"
                  contain
                  :src="`${API_URL_ASSETS}/backgrounds/certificate-design.png`"
                  @click="designType = $t('credentialDesign.preset.types[1]')"
                />
                <v-img
                  max-width="270px"
                  contain
                  :src="`${API_URL_ASSETS}/backgrounds/card-design.png`"
                  @click="designType = $t('credentialDesign.preset.types[2]')"
                />
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col cols="12" class="input-field">
                <label class="">
                  {{ $t(`credentialDesign.preset.label.${designType}`)
                  }}<span style="color: #e95e5e"> * </span></label>
                <v-text-field
                  v-model="templateName"
                  class="mt-2"
                  flat
                  solo
                  :error="templateNameError"
                  @input="templateNameError = false"
                />
              </v-col>
              <v-col cols="12" class="input-field">
                <div class="wrapper">
                  <div
                    v-if="photoFromFile"
                    class="uploaded-image"
                    :style="`background-image: url('${photoFromFile}')`"
                  />
                  <p class="small_text">
                    {{ $t(`credentialDesign.preset.photoLabel.${designType}`) }}
                  </p>
                  <p class="mb-0 small_text">
                    {{ $t('createCertModal.upload[1]') }}
                  </p>
                  <p class="small_text">
                    {{ $t('createCertModal.upload[2]') }}
                  </p>
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    accept="image/x-png,image/gif,image/jpeg"
                  >
                  <v-btn
                    class="back"
                    :class="{ 'error-btn': photoFileError }"
                    @click="chooseFiles()"
                  >
                    {{ $t('credentialDesign.uploadLogo') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <SuccessModal
      v-if="showConfirmCreateModal"
      :message="message"
      :design-type="designType.toLowerCase()"
      @close="handleClose()"
    />
  </v-container>
</template>

<style lang="scss">
.credential-design {
  .preset {
    .v-card {
      padding: 18px;
      padding-top: 6px;
      .error-btn {
        border-color: var(--coral) !important;
        .v-btn__content {
          color: var(--coral) !important;
        }
        &::before {
          background-color: var(--coral) !important;
        }
      }
      .error-radio {
        .v-icon,
        .v-label {
          color: var(--coral) !important;
        }
      }
      .uploaded-image {
        width: 93px;
        height: 93px;
        margin-right: 36px;
        border-radius: 50%;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        float: left;
        background-size: contain;
        background-position: center center;
      }
      .v-input {
        margin-top: 0;
        &--radio-group__input {
          justify-content: space-between !important;
        }
        .v-radio {
          width: 270px;
          margin-right: 0;
        }
        .v-radio:first-child {
          width: 186px;
        }
      }
      .input-field .v-text-field {
        @media screen and (min-width: 900px) {
          max-width: 65%;
        }
        .v-input__control {
          min-height: unset;
          .v-input__slot {
            margin-bottom: 4px;
            height: 44px;
            border-radius: 3px;
            border: solid 1px var(--light-grey-blue);
          }
          .v-text-field__details {
            margin-bottom: 4px;
          }
        }
      }
      .normal_text {
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
